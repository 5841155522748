<template>
  <div class="x-wrap">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公告管理" name="first">
          <div class="">
            <!-- 筛选条件 -->
            <div class="x-q-panel" style="margin-bottom: 0px">
              <el-row :gutter="20">
                <el-col :span="5">
                  <div class="x-con-item">
                    <label>公告标题：</label>
                    <el-input
                      placeholder="请输入标题"
                      maxlength="20"
                      v-model="query.title"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="3">
                  <el-button
                    type="primary"
                    class="x-q-btn"
                    @click="
                      query.pageIndex = 1;
                      doQuery();
                    "
                    >筛选</el-button
                  >
                  <el-button type="text" @click="doQueryReset">重置</el-button>
                </el-col>
              </el-row>
            </div>
            <div></div>
            <!-- 筛选结果 -->
            <div class="x-table-container">
              <div class="x-table-operation">
                <el-button
                  icon="el-icon-refresh"
                  type="primary"
                  plain
                  size="small"
                  @click="doRefresh"
                  >刷新</el-button
                >

                <el-button
                  type="primary"
                  size="small"
                  class="fr"
                  @click="goCreate"
                  >新增公告</el-button
                >
              </div>
              <div
                class="x-table"
                v-loading="showLoading"
                element-loading-text="数据刷新中..."
              >
                <el-table :data="tableData" stripe style="width: 100%">
                  <el-table-column prop="id" label="编号" width="200">
                    <template slot-scope="scope">
                      <el-link
                        type="primary"
                        :under-line="false"
                        @click="goDetail(scope.row.id)"
                        >{{ scope.row.id }}</el-link
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="title" label="标题"></el-table-column>
                  <el-table-column prop="content" label="内容摘要">
                  </el-table-column>
                  <el-table-column
                    prop="releaseTime"
                    label="发布时间"
                    width="230"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="updateTime"
                    label="更新时间"
                    width="230"
                  ></el-table-column>
                  <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                      <div class="flex-box-around">
                        <el-link
                          :underline="false"
                          type="primary"
                          @click="goUpdate(scope.row)"
                          >编辑</el-link
                        >

                        <el-link
                          :underline="false"
                          type="danger"
                          @click="doDelete(scope.row.id)"
                          >删除</el-link
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>

                <div class="x-pager right">
                  <el-pagination
                    background
                    layout="sizes, prev, pager, next"
                    :current-page.sync="query.pageIndex"
                    :page-size="query.pageSize"
                    :page-sizes="[10, 20, 30, 50]"
                    :total="total"
                    @size-change="setPageSize"
                    @current-change="setPageIndex"
                  ></el-pagination>
                </div>
              </div>
            </div></div
        ></el-tab-pane>
        <el-tab-pane label="轮播图管理" name="second">
          <div>
            <!-- 筛选结果 -->
            <div class="x-table-container">
              <div class="x-table-operation">
                <el-button
                  icon="el-icon-refresh"
                  type="primary"
                  plain
                  size="small"
                  @click="doRefresh"
                  >刷新</el-button
                >

                <el-button
                  type="primary"
                  size="small"
                  class="fr"
                  @click="goCreate"
                  >新增轮播图</el-button
                >
              </div>
              <div
                class="x-table"
                v-loading="showLoading"
                element-loading-text="数据刷新中..."
              >
                <el-table :data="tableData" stripe style="width: 100%">
                  <el-table-column prop="id" label="编号" width="100">
                    <template slot-scope="scope">
                      <el-link
                        type="primary"
                        :under-line="false"
                        @click="goDetail(scope.row.id)"
                        >{{ scope.row.id }}</el-link
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="title"
                    label="标题"
                    width="220"
                  ></el-table-column>
                  <el-table-column
                    prop="bannerUrl"
                    label="图片"
                    width="380"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div>
                        <img :src="scope.row.bannerUrl" alt="" />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="jumpUrl"
                    label="跳转链接"
                    width="300"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="status"
                    label="上架状态"
                    width="380"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div>
                        <el-switch
                          v-model="scope.row.status"
                          active-color="#13ce66"
                          active-value="1"
                          inactive-value="2"
                          @change="
                            (val) => {
                              doStatus(val, scope.row.id);
                            }
                          "
                        >
                        </el-switch>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="releaseTime"
                    label="发布时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    prop="updateTime"
                    label="更新时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                      <div class="flex-box-around">
                        <el-link
                          :underline="false"
                          type="primary"
                          @click="goUpdate(scope.row)"
                          >编辑</el-link
                        >
                        <el-link
                          :underline="false"
                          type="danger"
                          @click="doDelete(scope.row.id)"
                          >删除</el-link
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>

                <div class="x-pager right">
                  <el-pagination
                    background
                    layout="sizes, prev, pager, next"
                    :current-page.sync="query.pageIndex"
                    :page-size="query.pageSize"
                    :page-sizes="[10, 20, 30, 50]"
                    :total="total"
                    @size-change="setPageSize"
                    @current-change="setPageIndex"
                  ></el-pagination>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 邀请海报管理 -->
        <el-tab-pane label="邀请海报管理" name="third">
          <div>
            <!-- 筛选结果 -->
            <div class="x-table-container">
              <div class="x-table-operation">
                <el-button
                  icon="el-icon-refresh"
                  type="primary"
                  plain
                  size="small"
                  @click="doRefresh"
                  >刷新</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  class="fr"
                  @click="
                    () => {
                      this.$router.push('/poster/create');
                    }
                  "
                  >新增邀请海报</el-button
                >
              </div>
              <div
                class="x-table"
                v-loading="showLoading"
                element-loading-text="数据刷新中..."
              >
                <el-table :data="tableData" stripe style="width: 100%">
                  <el-table-column prop="id" label="编号" width="100">
                    <template slot-scope="scope">
                      <el-link
                        type="primary"
                        :under-line="false"
                        @click="goDetail(scope.row.id)"
                        >{{ scope.row.id }}</el-link
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="posterUrl"
                    label="图片"
                    width="380"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div>
                        <img :src="scope.row.posterUrl" alt="" />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="JumpUrl"
                    label="跳转链接"
                    width="300"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="status"
                    label="上架状态"
                    width="380"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div>
                        <el-switch
                          v-model="scope.row.status"
                          active-color="#13ce66"
                          active-value="1"
                          inactive-value="2"
                          @change="
                            (val) => {
                              doStatus(val, scope.row.id);
                            }
                          "
                        >
                        </el-switch>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="releaseTime"
                    label="发布时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    prop="updateTime"
                    label="更新时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                      <div class="flex-box-around">
                        <el-link
                          :underline="false"
                          type="primary"
                          @click="goUpdate(scope.row)"
                          >编辑</el-link
                        >
                        <el-link
                          :underline="false"
                          type="danger"
                          @click="doDelete(scope.row.id)"
                          >删除</el-link
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>

                <div class="x-pager right">
                  <el-pagination
                    background
                    layout="sizes, prev, pager, next"
                    :current-page.sync="query.pageIndex"
                    :page-size="query.pageSize"
                    :page-sizes="[10, 20, 30, 50]"
                    :total="total"
                    @size-change="setPageSize"
                    @current-change="setPageIndex"
                  ></el-pagination>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane> </el-tabs
    ></el-card>
  </div>
</template>

<script>
import {
  NewsQuery,
  imgQuery,
  NoticeRemove,
  NoticeEnable,
  BannerRemove,
  BannerOnline,
  PosterQuery,
  PosterOnline,
  PosterRemove,
} from "@/api/module/notice";

export default {
  name: "NoticeIndex",
  data() {
    return {
      showLoading: false,
      activeName: "first",
      query: {
        title: "",
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },
  created() {
    this.doQuery();
  },
  methods: {
    async doStatus(a, id) {
      let r;
      if (a == "1") {
        if (this.activeName == "second") {
          r = await BannerOnline({ id, status: 1 });
        } else if (this.activeName == "third") {
          r = await PosterOnline({ id, status: 1 });
        }
        if (r.code != 0) return;
        if (r.code == 0) {
          this.$message({
            type: "success",
            message: "上架成功！",
            duration: 1300,
            onClose: () => {
              this.doQuery();
            },
          });
        }
      } else {
        if (this.activeName == "second") {
          r = await BannerOnline({ id, status: 2 });
        } else if (this.activeName == "third") {
          r = await PosterOnline({ id, status: 2 });
        }
        if (r.code != 0) return;
        this.$message({
          type: "success",
          message: "下架成功！",
          duration: 1300,
          onClose: () => {
            this.doQuery();
          },
        });
      }
    },
    handleClick(tab) {
      this.$router.push(`/notice?tab=${tab.name}`);
      this.query = {
        title: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },
    // 查询
    async doQuery() {
      let q = this.$route.query.tab;
      if (q) {
        this.activeName = q;
      }
      let query = this.setQueryParams();
      if (this.activeName == "first") {
        const r = await NewsQuery(query);
        this.total = r.total;
        this.tableData = r.list;
      } else if (this.activeName == "second") {
        const r = await imgQuery(query);
        this.total = r.total;
        r.list.forEach((e) => {
          e.status = String(e.status);
        });
        this.tableData = r.list;
      } else if (this.activeName == "third") {
        const r = await PosterQuery(query);
        this.total = r.total;
        r.list.forEach((e) => {
          e.status = String(e.status);
        });
        this.tableData = r.list;
      }
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.activeName == "first") {
        if (this.query.title) {
          query += `&title=${this.query.title}`;
        }
      } else {
      }

      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },
    goDetail(id) {
      this.$router.push(`/notice/detail/${id}`);
    },
    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 删除
    doDelete(id) {
      const msg = "<span class='font-danger'>确认要删除本条数据吗？</span>";
      this.$alert(msg, "删除提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定删除",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            if (this.activeName == "first") {
              NoticeRemove({ id }).then((r) => {
                if (r.code != 0) return;
                if (r.code === 0) {
                  this.doQuery();
                  this.$message({
                    type: "success",
                    message: "删除成功",
                    duration: 1300,
                  });
                }
              });
            } else if (this.activeName == "second") {
              BannerRemove({ id }).then((r) => {
                if (r.code != 0) return;
                if (r.code === 0) {
                  this.doQuery();
                  this.$message({
                    type: "success",
                    message: "删除成功",
                    duration: 1300,
                  });
                }
              });
            } else {
              PosterRemove({ id }).then((r) => {
                if (r.code != 0) return;
                if (r.code === 0) {
                  this.doQuery();
                  this.$message({
                    type: "success",
                    message: "删除成功",
                    duration: 1300,
                  });
                }
              });
            }
          }
        },
      });
    },

    // 去新增轮播图
    goCreate() {
      this.activeName == "first"
        ? this.$router.push("/notice/create")
        : this.$router.push("/banner/create");
    },

    // 去详情
    goDetail(id) {
      this.activeName == "first"
        ? this.$router.push(`/notice/detail/${id}`)
        : this.$router.push(`/banner/detail/${id}`);
    },

    // 去编辑
    goUpdate(item) {
      if (this.activeName == "first") {
        const { id, title, content, imgUrl } = item;
        let oitem = {
          id,
          title,
          content,
          imgUrl,
        };
        this.$router.push({ name: "NoticeUpdate", params: oitem });
      } else if (this.activeName == "second") {
        const { id, title, bannerUrl, jumpUrl } = item;
        let oitem = {
          id,
          title,
          bannerUrl,
          jumpUrl,
        };
        this.$router.push({ path: `/banner/update`, query: oitem });
      } else if (this.activeName == "third") {
        const { id, posterUrl, JumpUrl } = item;
        let oitem = {
          id,
          posterUrl,
          JumpUrl,
        };
        this.$router.push({ path: `/poster/update`, query: oitem });
      }
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>
<style lang="less" scoped>
.x-table-operation {
  padding: 0 30px 20px 20px;
  margin-top: 20px;
  background-color: #fff;
}
.el-table__row {
  .cell {
    img {
      width: 100%;
      height: 130px;
    }
  }
}
</style>

